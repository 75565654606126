@tailwind base;
@tailwind components;
@tailwind utilities;

/* slick slider css files */
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";



@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/poppins/Poppins-Regular.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/poppins/Poppins-Medium.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  
  src: url("../public/assets/fonts/poppins/Poppins-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/poppins/Poppins-ExtraBold.ttf");
  font-weight: 900;
  font-style: normal;
}



@font-face {
  font-family: "Geologica";
  src: url("../public/assets/fonts/Geologica/Geologica-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  color: #163A5E;
}

body, html {
  overscroll-behavior: none;
  height: 100vh !important;
}

input[type='time']  {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.bg-free-circle {
  background-image: url(/public/assets/media/svg/free-circle.svg);
}

.slick-slider .slick-track, .slick-slider .slick-list {
  overflow: unset;
}

.slick-track {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  margin: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  margin: 12px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:#e7e8f3; 
  border-radius: 10px;
  margin: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d9d9df; 
  margin: 5px;
}

reach-portal {
  z-index: 9999;
  position: relative;
}

[data-rsbs-scroll] {
  -webkit-overflow-scrolling: auto  !important;
}

[data-rsbs-backdrop] {
  pointer-events: none !important;
}

[data-rsbs-overlay] {
  max-height: 85vh;
  border-radius: 15px 15px 0 0 !important;
  z-index: 100 !important;
}

.min-height-16 [data-rsbs-content] {
  min-height: 16vh;
}

[data-rsbs-scroll] {
  max-height: 88vh;
}

[data-rsbs-header],
[data-rsbs-footer] {
  box-shadow: none !important;
}

[data-rsbs-footer] {
  padding: 0 20px  !important;
}

[data-rsbs-header] {
  padding-top: 24px !important;
}

[data-rsbs-header]:before {
  width: 75px !important;
  height: 5px !important;
  border-radius: 10px !important;
  margin-top: 3px;
  top: 8px !important;
}
.fc-header-toolbar .fc-toolbar-title {
  font-weight: 700;
}

.fc-popover.fc-more-popover {
  z-index: 15;
}

.fc-event.fc-timegrid-event.fc-v-event {
  border-color: #fff !important;
}
.fc-event.fc-daygrid-event.fc-daygrid-block-event.fc-h-event {
  border-color: #fff !important;
  margin-bottom: 4px !important;
}

/* @media only screen and (max-width: 768px) {
  .fc-view-harness.fc-view-harness-active{
    min-height: 450px;
  }
  .fc-header-toolbar {
    flex-direction: column;
    position: relative;
    align-items: start !important;
  }
  .fc-header-toolbar div:nth-child(2 of .fc-toolbar-chunk) {
      order: 3;
      padding-top: 20px;
      width: 100%;
  }
  .fc-header-toolbar div:nth-child(3 of .fc-toolbar-chunk) {
      order: 1;
  }
  .fc-daygrid-day-frame .fc-event.fc-daygrid-event.fc-daygrid-block-event.fc-h-event {
    margin-bottom: 1px !important;
    height: 5px;
  }
  
  .fc-daygrid-day-frame .fc-h-event .fc-event-main,
  .fc-daygrid-day-frame .fc-h-event .fc-event-main-frame,
  .fc-daygrid-day-frame .fc-h-event .fc-event-title {
    height: 100%;
  }
  .fc-header-toolbar .fc-toolbar-title {
    font-size: 18px;
    text-align: center;
  }
  .fc-header-toolbar div:nth-child(1 of .fc-toolbar-chunk) .fc-button-group {
    position: absolute !important;
    bottom: 0;
    justify-content: space-between;
    width: 100%;
    left: 0;
  }
  .fc-header-toolbar div:nth-child(1 of .fc-toolbar-chunk) .fc-button-group button {
    flex-grow: 0;
    padding: 3px;
  }
  .fc-today-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .fc-daygrid-event.fc-daygrid-block-event.fc-h-event,
  .fc-col-header-cell-cushion,
  .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size: 12px;
  }
  .fc-daygrid-more-link.fc-more-link {
    font-size: 10px;
  }
  .fc-timeGridDay-view.fc-view.fc-timegrid .fc-timegrid-slot.fc-timegrid-slot-lane {
    background-color: #fff;
  }
} */


/********************************** React date picker **********************************/
.react-datepicker *{
  color: #163A5E;
  font-family: Poppins, sans-serif;
}
.react-datepicker__header {
  background-color: #fff !important;
  border: none !important;
  margin: 0 0.4rem !important;
  padding-top: 0 !important;
}

.react-datepicker__day,
.react-datepicker__day-name {
  font-weight: 600;
  line-height: 1.2rem !important;
  width: 2rem !important;
  height: 2.5rem !important;
}

.react-datepicker__current-month {
  margin-bottom: 16px;
}

.react-datepicker__day--outside-month {
  color: #828282!important;
}

.react-datepicker__day--selected {
 background-color:  #fff !important;
 border: 1px solid #163A5E !important; 
 color: #163A5E !important;
}

.react-datepicker__navigation {
  top: 15px !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 8px;
}

.react-datepicker__navigation-icon {
  top: 4px !important;
}

.react-datepicker__navigation--next {
  right: 26px !important;
}

.react-datepicker__navigation--previous {
  left: 26px !important;
}

.react-datepicker__navigation-icon--next {
  left: 1px !important;
}

.react-datepicker__navigation-icon--previous {
  right: 1px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #163A5E!important;
  border-width: 2px 2px 0 0 !important;
}

.react-datepicker__month-container, .react-datepicker__tab-loop {
  width: 100%;
}

.react-datepicker__tab-loop {
  grid-column: span 2;
  justify-self: end;
  order: 3;
}

.react-datepicker__day-names, .react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__day--highlighted-custom, .react-datepicker__day--highlighted-custom-one::before, .react-datepicker__day--highlighted-custom-two::after {
  border-radius: 100%;
  content: "";
  height: 4px;
  width: 4px;
}
.react-datepicker__day--highlighted-custom-one::before {
  transform: translateX(-1px) translateY(25px);
  position: absolute;
  background: #2bcd5a;
}
.react-datepicker__day--highlighted-custom-two::after {
  transform: translateX(-3px) translateY(25px);
  position: absolute;
  background: #ffbf00;
}
.react-datepicker__day--highlighted-custom-std, .react-datepicker__day--highlighted-custom-private-std, .react-datepicker__day--highlighted-custom-test, .react-datepicker__day--highlighted-custom-integration {
  background-position-x: center;
  background-position-y: 19px;
  background-size: 38px;
  background-repeat: no-repeat !important;
}
@media (max-width: 767px) {
  .react-datepicker__day--highlighted-custom-std, .react-datepicker__day--highlighted-custom-private-std, .react-datepicker__day--highlighted-custom-test, .react-datepicker__day--highlighted-custom-integration {
    background-size: 28px;
 }
}
.react-datepicker__day--highlighted-custom-std {
  background-image: url('../public/assets/media/student-line.svg');
}
.react-datepicker__day--highlighted-custom-private-std {
  background-image: url('../public/assets/media/private-student-line.svg');
}
.react-datepicker__day--highlighted-custom-test {
  background-image: url('../public/assets/media/test-line.svg');
}
.react-datepicker__day--highlighted-custom-integration {
  background-image: url('../public/assets/media/integration-line.svg');
}
.react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-test, .react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-private-std, .react-datepicker__day--highlighted-custom-private-std.react-datepicker__day--highlighted-custom-test, .react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-integration, .react-datepicker__day--highlighted-custom-test.react-datepicker__day--highlighted-custom-integration, .react-datepicker__day--highlighted-custom-private-std.react-datepicker__day--highlighted-custom-integration {
  background-position-y: 19px, 24px;
}
.react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-test {
  background-image: url('../public/assets/media/student-line.svg'), url('../public/assets/media/test-line.svg');
}
.react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-private-std {
  background-image: url('../public/assets/media/student-line.svg'), url('../public/assets/media/private-student-line.svg');
}
.react-datepicker__day--highlighted-custom-private-std.react-datepicker__day--highlighted-custom-test {
  background-image: url('../public/assets/media/private-student-line.svg'), url('../public/assets/media/test-line.svg');
}
.react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-integration {
  background-image: url('../public/assets/media/student-line.svg'), url('../public/assets/media/integration-line.svg');
}
.react-datepicker__day--highlighted-custom-private-std.react-datepicker__day--highlighted-integration {
  background-image: url('../public/assets/media/private-student-line.svg'), url('../public/assets/media/integration-line.svg');
}
.react-datepicker__day--highlighted-custom-test.react-datepicker__day--highlighted-custom-integration {
  background-image: url('../public/assets/media/test-line.svg'), url('../public/assets/media/integration-line.svg');
}
.react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-private-std.react-datepicker__day--highlighted-custom-test {
  background-image: url('../public/assets/media/student-line.svg'), url('../public/assets/media/private-student-line.svg'), url('../public/assets/media/test-line.svg');
  background-position-y: 19px, 24px, 29px;
}
.react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-private-std.react-datepicker__day--highlighted-custom-integration {
  background-image: url('../public/assets/media/student-line.svg'), url('../public/assets/media/private-student-line.svg'), url('../public/assets/media/test-line.svg');
  background-position-y: 19px, 24px, 29px;
}
.react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-test.react-datepicker__day--highlighted-custom-integration {
  background-image: url('../public/assets/media/student-line.svg'), url('../public/assets/media/private-student-line.svg'), url('../public/assets/media/test-line.svg');
  background-position-y: 19px, 24px, 29px;
}
.react-datepicker__day--highlighted-custom-private-std.react-datepicker__day--highlighted-custom-test.react-datepicker__day--highlighted-custom-integration {
  background-image: url('../public/assets/media/student-line.svg'), url('../public/assets/media/private-student-line.svg'), url('../public/assets/media/test-line.svg');
  background-position-y: 19px, 24px, 29px;
}
.react-datepicker__day--highlighted-custom-std.react-datepicker__day--highlighted-custom-private-std.react-datepicker__day--highlighted-custom-test.react-datepicker__day--highlighted-custom-integration {
  background-image: url('../public/assets/media/student-line.svg'), url('../public/assets/media/private-student-line.svg'), url('../public/assets/media/test-line.svg'), url('../public/assets/media/integration-line.svg');
  background-position-y: 18px, 22px, 26px, 30px;
}


.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
  @apply !leading-none font-normalf text-darkBlue h-11 top-0
}

.graph-container .recharts-bar {
  stroke: #000;
  stroke-width: 1px;
}

.recharts-reference-line-label {
  background: #163a5e;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
}